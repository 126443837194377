'use client'

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import {
  CalendarSettings,
  JobListing as JobListingType,
  useGetCalendarSettingsQuery,
  useGetInterviewerCalendarsQuery,
  useGetJobListingsIdTitleQuery,
} from '~/bff/graphql/generated/graphql'
import { useCalendarView } from './calendar-view-context'
import type { EventType } from './context.types'
import { isExternalEventVisible, isJobListingVisible } from './context.utils'
import { getEventCardColors, getJobColor } from './event-color.utils'

export type JobListing = Pick<JobListingType, 'id' | 'listingTitle'>

interface CalendarEventsContextProps {
  events: any[]
  showExternalEvents: boolean
  toggleShowExternalEvents: () => void
  selectedCalendars: string[]
  selectCalendar: (id: string, isChecked: boolean) => void

  selectedCompanyUserCalendarSettings: string[]
  /**
   * @param id company user id
   */
  selectCompanyUserCalendarSettings: (id: string[]) => void

  resources: any[]

  jobListings: JobListing[]
  selectedJobListings: Array<number>
  selectJobListing: (key: number, isChecked: boolean) => void
  isLoading: boolean
  calendarLoading: (loading: boolean) => void
  refetchCalendars: () => void
}

export const CalendarEventsContext = createContext<CalendarEventsContextProps>({
  isLoading: true,
  calendarLoading: (loading: boolean) => {},
  events: [],
  showExternalEvents: false,
  toggleShowExternalEvents: () => {},
  selectedCalendars: [],
  selectCalendar: () => {},
  resources: [],
  jobListings: [],
  selectedJobListings: [],
  selectJobListing: () => {},

  selectedCompanyUserCalendarSettings: [],
  selectCompanyUserCalendarSettings: (id: string[]) => {},
  refetchCalendars: () => {},
})

export const CalendarEventsProvider = ({ children }: React.PropsWithChildren) => {
  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(true)
  const [showExternalEvents, setShowExternalEvents] = useState<boolean>(false)
  const [selectedJobListings, setSelectedJobListings] = useState<Array<number>>([])
  const [selectedCalendars, setSelectedCalendars] = useState<Array<string>>([])
  const [selectedCompanyUserCalendarSettings, setSelectedCompanyUserCalendarSettings] = useState<Array<string>>([])

  const { data: currentUserCallendarSettings, refetch: refetchCurrentUserCallendarSettings } =
    useGetCalendarSettingsQuery()

  const { isVerticalResources } = useCalendarView()
  const {
    data: interviewersData,
    loading: isLoadingInterviewers,
    refetch: refetchInterviewersData,
  } = useGetInterviewerCalendarsQuery({
    fetchPolicy: 'no-cache',
  })

  const refetchCalendars = useCallback(
    () => Promise.all([refetchCurrentUserCallendarSettings(), refetchInterviewersData()]),
    [refetchCurrentUserCallendarSettings, refetchInterviewersData],
  )

  const { data: jobListingsData, loading: isLoadingJobListings } = useGetJobListingsIdTitleQuery()

  const calendarLoading = (loading: boolean) => setIsCalendarLoading(loading)

  const toggleShowExternalEvents = useCallback(() => setShowExternalEvents(!showExternalEvents), [showExternalEvents])
  // Toggle Calendar

  const selectJobListing = useCallback((id: number, isChecked: boolean) => {
    setSelectedJobListings((prev) => {
      if (isChecked) return prev.includes(id) ? prev : [...prev, id]
      else return prev.filter((item) => item !== id)
    })
  }, [])

  const selectCompanyUserCalendarSettings = useCallback(
    (interviewerCalendarIds: string[]) => {
      if (isEqual(selectedCompanyUserCalendarSettings, interviewerCalendarIds)) return
      setSelectedCompanyUserCalendarSettings(interviewerCalendarIds)
    },
    [selectedCompanyUserCalendarSettings],
  )

  const selectCalendar = useCallback((id: string, isChecked: boolean) => {
    setSelectedCompanyUserCalendarSettings((prev) => {
      if (isChecked) return prev.includes(id) ? prev : [...prev, id]
      else return prev.filter((item) => item !== id)
    })
    setSelectedCalendars((prev) => {
      if (isChecked) return prev.includes(id) ? prev : [...prev, id]
      else return prev.filter((item) => item !== id)
    })
  }, [])

  const jobListings = useMemo(() => {
    if (!jobListingsData?.GetJobListing) return []

    return jobListingsData.GetJobListing.map((job) => ({
      ...job,
      color: getJobColor(job.id),
    }))
  }, [jobListingsData])

  // GetCompanyUsers -> CalendarSettings -> Calendars
  const getCalendarsToUse = useCallback(() => {
    const calendarsToUse = interviewersData?.GetCompanyUsers?.map((cu) => cu.CalendarSettings)
      .filter((settings): settings is CalendarSettings => settings !== null && settings !== undefined)
      .flatMap((calendar) => calendar?.Calendars || [])
      .filter((calendar) => selectedCompanyUserCalendarSettings.includes(calendar.id))

    return calendarsToUse ?? []
  }, [interviewersData, selectedCompanyUserCalendarSettings])

  /**
   * Transform CalendarEvents to EventSourceInput (fullcalendar type expectation)
   * Add original event and calendar(without events)
   * EventSourceInput: resourceId, calendarColor, title, start, end
   */

  const events = useMemo(() => {
    // Get calendar sources to display
    const calendarsToUse = getCalendarsToUse()
    // Get events from calendar sources
    const baseEvents =
      calendarsToUse?.flatMap(
        (calendar) =>
          calendar.CalendarEvents?.map((event) => {
            const { CalendarEvents, ...props } = calendar

            const { calendarColor, interviewColor } = getEventCardColors(event, calendar, isVerticalResources)
            const editable = !isVerticalResources

            const eventInput: EventType = {
              calendar: props,
              id: event.id,
              originalEvent: { ...event, editable },
              resourceId: calendar.id,
              calendarColor,
              interviewColor,
              title: event.title || '',
              start: event.startTime,
              end: event.endTime,
              editable: !isVerticalResources,
            }
            return eventInput
          }) || [],
      ) || []

    let filteredEvents = baseEvents.filter(isExternalEventVisible(showExternalEvents))
    filteredEvents = filteredEvents.filter(isJobListingVisible(selectedJobListings))
    return filteredEvents
  }, [showExternalEvents, selectedJobListings, getCalendarsToUse, isVerticalResources])

  // Only applicable under Schedule Interview Drawer
  const resources = useMemo(() => {
    const calendarsToUse = getCalendarsToUse()

    return calendarsToUse?.map((calendar) => ({
      id: calendar.id,
      title: calendar.name,
      calendarColor: calendar.color,
    }))
  }, [getCalendarsToUse])

  // Select all job listings by default to display
  useEffect(() => {
    if (jobListings?.length) {
      const allJobListingIds = jobListings.map((job) => job.id)
      setSelectedJobListings(allJobListingIds)
    }
  }, [jobListings])

  const isLoading = isLoadingInterviewers || isLoadingJobListings || isCalendarLoading
  const value = useMemo(
    () => ({
      isLoading,
      events,
      showExternalEvents,
      toggleShowExternalEvents,
      selectedCalendars,
      selectCalendar,
      resources,
      jobListings,
      selectedJobListings,
      selectJobListing,
      selectedCompanyUserCalendarSettings,
      selectCompanyUserCalendarSettings,
      calendarLoading,
      refetchCalendars,
    }),
    [
      isLoading,
      events,
      showExternalEvents,
      toggleShowExternalEvents,
      selectedCalendars,
      selectCalendar,
      resources,
      jobListings,
      selectedJobListings,
      selectJobListing,
      selectedCompanyUserCalendarSettings,
      selectCompanyUserCalendarSettings,
      refetchCalendars,
    ],
  )

  return <CalendarEventsContext.Provider value={value}>{children}</CalendarEventsContext.Provider>
}

export const useCalendarEvents = () => {
  const context = useContext(CalendarEventsContext)
  if (!context) {
    throw new Error('useCalendarEvents must be used within CalendarEventsProvider')
  }
  return context
}
