import { useMemo } from 'react'
import { useUserContext } from '~/src/providers/user-provider'
import { CalendarEventsProvider } from './calendar-events-context'
import { CalendarRefsProvider } from './calendar-refs-context'
import { CalendarViewProvider } from './calendar-view-context'

export const CalendarProvider = ({ children }: React.PropsWithChildren) => {
  const { currentWorkspace } = useUserContext()

  const isCompanyUser = useMemo(() => !!currentWorkspace?.currentCompany, [currentWorkspace])

  if (!isCompanyUser) {
    return <>{children}</>
  }

  return (
    <CalendarRefsProvider>
      <CalendarViewProvider>
        <CalendarEventsProvider>{children}</CalendarEventsProvider>
      </CalendarViewProvider>
    </CalendarRefsProvider>
  )
}
