import { signOut } from 'next-auth/react'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import logger from '~/utils/logger'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { extensions, message, locations, path } = error
      const originalError: any = extensions?.originalError
      const code = extensions?.code || originalError?.extensions?.code

      if (code === 'TOKEN_EXPIRED' || code === 'TOKEN_NOT_VERIFIED' || code === 'INVALID_TOKEN') {
        signOut({ callbackUrl: '/' })
      }
      logger.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
    })
  } else if (networkError) {
    logger.error(`[Network error]: ${networkError}`)
  }
})

const httpLink = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_APP_URL}/api/graphql`,
  credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
  return {
    headers,
  }
})

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})

export default apolloClient
