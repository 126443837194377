import { Calendar, GetInterviewerCalendarsQuery } from '~/bff/graphql/generated/graphql'
import { getFullName } from '~/utils/get-full-name'
import { EventType } from './context.types'
import { getCalendarColor } from './event-color.utils'

type EventCondition = (event: EventType) => boolean

export const isExternalEventVisible = (showExternalEvents: boolean): EventCondition => {
  return (event) => showExternalEvents || showExternalEvents === !event.originalEvent.isScalisEvent
}

export const isJobListingVisible = (selectedJobListings: number[]): EventCondition => {
  return (event) => {
    const regardingJobs = event.originalEvent.regardingJobs
    if (regardingJobs?.length > 0) {
      return regardingJobs?.some((jobId: number) => selectedJobListings.includes(jobId))
    }
    return true
  }
}
export const removeExternalEvents = (calendars?: Calendar[]) => {
  return calendars?.map((calendar) => {
    return {
      ...calendar,
      CalendarEvents: calendar.CalendarEvents?.filter((event) => event.isScalisEvent),
    }
  })
}

/**
 * Get interviewers options for UserSelect component
 * @param interviewersData - interviewers data
 * @param excludeHostCalendar - exclude host calendar from the options
 * @returns interviewers options
 */
export const getInterviewersOptions = (
  interviewersData?: GetInterviewerCalendarsQuery,
  excludeHostCalendar?: boolean,
) => {
  if (!interviewersData) return []
  let companyUsers = interviewersData?.GetCompanyUsers
  if (excludeHostCalendar) {
    const hostCompanyUserId = interviewersData.GetCalendarSettings?.companyUserId.toString()
    companyUsers = interviewersData?.GetCompanyUsers?.filter((companyUser) => companyUser.id !== hostCompanyUserId)
  }
  const interviewersOptions = companyUsers?.map((interviewer) => {
    const calendarConnected = !!interviewer.CalendarSettings
    return {
      label: getFullName(
        interviewer.UserInvitation?.ScalisUser?.firstName,
        interviewer.UserInvitation?.ScalisUser?.lastName,
      ),
      value: interviewer.id,
      email: interviewer.CalendarSettings?.Calendars?.[0]?.nylasCalendarId,
      disabled: !calendarConnected,
      calendarConnected,
      color: getCalendarColor(Number(interviewer.CalendarSettings?.Calendars?.[0]?.id)),
    }
  })
  return interviewersOptions || []
}

export const findInterviewerCalendarId = (interviewerId?: string, interviewersData?: GetInterviewerCalendarsQuery) => {
  const interviewer = interviewersData?.GetCompanyUsers?.find((u) => u.id === interviewerId)
  return interviewer?.CalendarSettings?.Calendars?.[0]?.id
}
