import tailwindConfig from 'tailwind.config.design-system'
import resolveConfig from 'tailwindcss/resolveConfig'
import { NameEnum } from '~/bff/graphql/generated/graphql'

export enum StatusColorMap {
  DEFAULT = 'sourcing',
  SOURCING = 'sourcing',
  APPLICATION_REVIEW = 'application-review',
  PRE_SCREEN = 'pre-screen',
  INTERVIEWS = 'interviews',
  OFFER = 'offer',
  HIRED = 'hired',
}

export interface PipelineStep {
  id: number
  status?: StatusColorMap
  label: string
  count?: number
}

const fullConfig = resolveConfig(tailwindConfig)

const nameToStatusColorMap: Record<NameEnum, StatusColorMap> = {
  [NameEnum.ApplicationReview]: StatusColorMap.APPLICATION_REVIEW,
  [NameEnum.Hired]: StatusColorMap.HIRED,
  [NameEnum.Interviewing]: StatusColorMap.INTERVIEWS,
  [NameEnum.Offer]: StatusColorMap.OFFER,
  [NameEnum.PreScreen]: StatusColorMap.PRE_SCREEN,
  [NameEnum.Sourcing]: StatusColorMap.SOURCING,
}

export const getStatusColor = (name: NameEnum): StatusColorMap => {
  return nameToStatusColorMap[name] || StatusColorMap.DEFAULT
}

export const getViewName = (name: NameEnum): string => {
  const viewNameMap: Record<NameEnum, string> = {
    [NameEnum.ApplicationReview]: 'Application Review',
    [NameEnum.Hired]: 'Hired',
    [NameEnum.Interviewing]: 'Interviews',
    [NameEnum.Offer]: 'Offer',
    [NameEnum.PreScreen]: 'Pre-Screen',
    [NameEnum.Sourcing]: 'Sourcing',
  }

  return viewNameMap[name] || ''
}

export const statusColorMap = new Map([
  [StatusColorMap.SOURCING, fullConfig.theme.backgroundColor['dataviz-violet-30'].toString()],
  [StatusColorMap.APPLICATION_REVIEW, fullConfig.theme.backgroundColor['dataviz-lilac-blue-30'].toString()],
  [StatusColorMap.PRE_SCREEN, fullConfig.theme.backgroundColor['dataviz-cyan-30'].toString()],
  [StatusColorMap.INTERVIEWS, fullConfig.theme.backgroundColor['dataviz-teal-30'].toString()],
  [StatusColorMap.OFFER, fullConfig.theme.backgroundColor['dataviz-lime-30'].toString()],
  [StatusColorMap.HIRED, fullConfig.theme.backgroundColor['dataviz-yellow-30'].toString()],
])
