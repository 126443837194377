'use client'

import { isServer, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SessionProvider } from 'next-auth/react'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '~/bff/apolloClient'
import { TooltipProvider } from '~/components/v2/Tooltip'
import { CalendarProvider } from '~/src/app/company/(dashboard)/calendar/context'
import { Company, CompanyWorkspace } from '~/types/auth/TokenPayload'
import { getUser } from '~/v2/helpers/workspace-helper'
import { UserProvider } from './user-provider'

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  })
}

let browserQueryClient: QueryClient | undefined = undefined

function getQueryClient() {
  if (isServer) {
    return makeQueryClient()
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
  }
}

interface ClientProvidersProps {
  children: React.ReactNode
  subdomain: string | null
  user: Awaited<ReturnType<typeof getUser>> | null
  currentWorkspace: CompanyWorkspace | null
  companies: Company[] | null
}

export default function ClientProviders({
  children,
  subdomain,
  user,
  currentWorkspace,
  companies,
}: ClientProvidersProps) {
  const queryClient = getQueryClient()

  return (
    <ApolloProvider client={apolloClient}>
      <SessionProvider>
        <UserProvider
          subdomain={subdomain}
          user={user}
          currentWorkspace={currentWorkspace}
          companies={companies}
        >
          <TooltipProvider>
            <QueryClientProvider client={queryClient}>
              <CalendarProvider>{children}</CalendarProvider>
            </QueryClientProvider>
          </TooltipProvider>
        </UserProvider>
      </SessionProvider>
    </ApolloProvider>
  )
}
