import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

class DatadogSingleton {
  private static instance: DatadogSingleton
  private isInitialized = false

  public logs = datadogLogs
  public rum = datadogRum

  private constructor() {}

  public static getInstance(): DatadogSingleton {
    if (!DatadogSingleton.instance) {
      DatadogSingleton.instance = new DatadogSingleton()
    }
    return DatadogSingleton.instance
  }

  public initialize() {
    if (this.isInitialized) return
    this.isInitialized = true

    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
    const datadogEnv = process.env.NEXT_PUBLIC_DATADOG_ENV
    const appUrl = process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL
    // Default session replay rate is 20% and sample rate is 10% if not specified in env vars
    // This is only applicable for the scalis-prod
    const sessionReplayRate = Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_RATE) || 20
    const sessionSampleRate = Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE) || 10

    if (!applicationId || !clientToken || !datadogEnv || !appUrl || !sessionReplayRate || !sessionSampleRate) {
      // Since we can't use Datadog logs here, we'll throw an error instead of logging it
      throw new Error('Datadog configuration missing - required environment variables are not set')
    }

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service: `scalis-${datadogEnv}-fe`,
      env: datadogEnv,
      // Default session for other environments than production is 10%.
      // If the NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE is set, it will be used instead.
      sessionSampleRate: datadogEnv === 'prod' ? sessionSampleRate : 10,
      // Default session replay for other environments than production is 0%
      // If the NEXT_PUBLIC_DATADOG_SESSION_REPLAY_RATE is set, it will be used instead.
      sessionReplaySampleRate: datadogEnv === 'prod' ? sessionReplayRate : 0,
      trackSessionAcrossSubdomains: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [appUrl],
      enableExperimentalFeatures: ['logs'],
    })

    datadogLogs.init({
      clientToken,
      site: 'datadoghq.com',
      service: `scalis-${datadogEnv}-fe`,
      env: datadogEnv,
      sendLogsAfterSessionExpiration: true,
      trackSessionAcrossSubdomains: true,
      forwardErrorsToLogs: false,
    })
  }
}

export const Datadog = DatadogSingleton.getInstance()
