'use client'

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useGetCalendarSettingsQuery } from '~/bff/graphql/generated/graphql'
import { VIEW_TYPE } from '~/scalis-components/core/calendar/calendar.constants'
import { NAVIGATE_TYPE, NavigateType, ViewType } from '~/scalis-components/core/calendar/calendar.types'
import { useCalendarRefs } from './calendar-refs-context'

interface CalendarViewContextProps {
  view: ViewType
  fullCalendarView: string
  changeView: (viewType: ViewType) => void
  isVerticalResources: boolean
  setIsVerticalResources: (value: boolean) => void
  showWeekend: boolean
  toggleShowWeekend: () => void
  navDateTitle: string
  selectedDate: Date
  selectDate: (date?: Date) => void
  navigateCalendar: (type: NavigateType) => void

  timezone: string
  changeTimezone: (timezone: string) => void
}

export const CalendarViewContext = createContext<CalendarViewContextProps>({
  view: VIEW_TYPE.MONTH,
  fullCalendarView: 'dayGridMonth',
  changeView: () => {},
  isVerticalResources: false,
  setIsVerticalResources: () => {},
  showWeekend: false,
  toggleShowWeekend: () => {},
  navDateTitle: '',
  selectedDate: new Date(),
  selectDate: () => {},
  navigateCalendar: () => {},
  timezone: '',
  changeTimezone: () => {},
})

export const CalendarViewProvider = ({ children }: React.PropsWithChildren) => {
  const [view, setView] = useState<ViewType>(VIEW_TYPE.MONTH)
  const [fullCalendarView, setFullCalendarView] = useState<string>('dayGridMonth')
  const [isVerticalResources, setIsVerticalResources] = useState<boolean>(false)
  const [showWeekend, setShowWeekend] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [navDateTitle, setNavDateTitle] = useState<string>('')
  const [timezone, setTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const { mainCalendarRef, drawerCalendarRef } = useCalendarRefs()

  const { data: calendarSettings } = useGetCalendarSettingsQuery()

  useEffect(() => {
    if (calendarSettings?.GetCalendarSettings?.timeZone) {
      setTimezone(calendarSettings.GetCalendarSettings.timeZone)
    }
  }, [calendarSettings?.GetCalendarSettings?.timeZone])

  const changeTimezone = (timezone: string) => setTimezone(timezone)

  const toggleShowWeekend = () => setShowWeekend(!showWeekend)

  const changeView = (viewType: ViewType) => {
    const currentView = viewType
    setView(currentView)
    switch (currentView) {
      case VIEW_TYPE.MONTH:
        drawerCalendarRef?.current?.getApi().changeView('dayGridMonth')
        mainCalendarRef?.current?.getApi().changeView('dayGridMonth')
        setFullCalendarView('dayGridMonth')
        break
      case VIEW_TYPE.WEEK:
        drawerCalendarRef?.current?.getApi().changeView('timeGridWeek')
        mainCalendarRef?.current?.getApi().changeView('timeGridWeek')
        setFullCalendarView('timeGridWeek')
        break
      case VIEW_TYPE.DAY:
        drawerCalendarRef?.current ? setFullCalendarView('resourceTimeGridDay') : setFullCalendarView('timeGridDay')
        drawerCalendarRef?.current?.getApi().changeView('resourceTimeGridDay')
        mainCalendarRef?.current?.getApi().changeView('timeGridDay')
        break
    }
  }

  const selectDate = useCallback((date?: Date) => {
    if (date) setSelectedDate(date)
  }, [])

  const changeNavDateTitle = useCallback((date: Date) => {
    if (!date) return
    const title = date?.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    })
    setNavDateTitle(title)
  }, [])

  useEffect(() => {
    setSelectedDate(new Date())
    changeNavDateTitle(new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateCalendar = (type: NavigateType) => {
    if (type === NAVIGATE_TYPE.NEXT) {
      drawerCalendarRef?.current?.getApi().next()
      mainCalendarRef?.current?.getApi().next()
    } else {
      drawerCalendarRef?.current?.getApi().prev()
      mainCalendarRef?.current?.getApi().prev()
    }

    changeNavDateTitle(drawerCalendarRef?.current?.getApi().getDate())
    changeNavDateTitle(mainCalendarRef?.current?.getApi().getDate())
  }

  const value = useMemo(
    () => ({
      view,
      fullCalendarView,
      changeView,
      isVerticalResources,
      setIsVerticalResources,
      showWeekend,
      toggleShowWeekend,
      navDateTitle,
      selectedDate,
      selectDate,
      navigateCalendar,
      timezone,
      changeTimezone,
    }),
    [
      view,
      fullCalendarView,
      changeView,
      isVerticalResources,
      setIsVerticalResources,
      showWeekend,
      toggleShowWeekend,
      navDateTitle,
      selectedDate,
      selectDate,
      navigateCalendar,
      timezone,
      changeTimezone,
    ],
  )

  return <CalendarViewContext.Provider value={value}>{children}</CalendarViewContext.Provider>
}

export const useCalendarView = () => {
  const context = useContext(CalendarViewContext)
  if (!context) {
    throw new Error('useCalendarView must be used within CalendarViewProvider')
  }
  return context
}
