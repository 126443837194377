'use client'

import { useEffect, useRef } from 'react'
import { useUserContext } from '~/src/providers/user-provider'
import { getFullName } from '~/utils/get-full-name'
import { Datadog } from '../utils/scalis-datadog'

export default function DatadogInit() {
  const hasInitialized = useRef(false)

  const { user, primaryEmail } = useUserContext()

  useEffect(() => {
    if (!hasInitialized.current) {
      Datadog.initialize()
      hasInitialized.current = true
    }

    if (user) {
      Datadog.rum.setUser({
        id: user.id.toString(),
        name: getFullName(user.firstName, user.lastName),
        email: primaryEmail?.emailAddress ?? '',
      })
    }
  }, [user, primaryEmail])

  return null
}
