import tailwindConfig from 'tailwind.config.design-system'
import resolveConfig from 'tailwindcss/resolveConfig'
import { Calendar, NameEnum } from '~/bff/graphql/generated/graphql'
import { CalendarEvent } from '~/bff/graphql/generated/graphql'
import { getStatusColor, statusColorMap } from '~/utils/getStageColor'

const fullConfig = resolveConfig(tailwindConfig)

/**
 * Array of distinct light colors for good text contrast randomly generated
 * To be applied on event cards
 */
const colorPalette = [
  '#b3c6ff', // Light blue
  '#c7b4f5', // Light purple
  '#b4d0b4', // Light green
  '#f5b4b4', // Light coral
  '#b4e6e6', // Light teal
  '#f5d0b4', // Light orange
  '#d0b4f5', // Light lavender
  '#b4f5d0', // Light mint
  '#f5b4e6', // Light pink
  '#f5f5b4', // Light yellow
  '#b4d8f5', // Light sky blue
  '#e6b4f5', // Light magenta
  '#f5c7b4', // Light peach
  '#c7f5b4', // Light lime
  '#b4f5e6', // Light aqua
  '#f5b4d0', // Light rose
  '#d8b4f5', // Light violet
  '#f5e6b4', // Light cream
  '#b4f5f5', // Light cyan
  '#f5b4f5', // Light fuchsia
]

/**
 * The idea here is to guarantee unique color (per session) based on job or calendar Id
 */
const jobColorMap = new Map<number, string>()
let jobColorIndex = 0

const calendarColorMap = new Map<number, string>()
let calendarColorIndex = 0
/**
 * A second set of colors to be applied on event cards
 */
const datavizPalette = Object.values(fullConfig.theme.backgroundColor.dataviz).map((color) =>
  Object.values(color).at(0),
) as string[]

const selectColor = (
  palette: string[],
  calendarOrJobColorMap: Map<number, string>,
  index: number,
  ids?: number | number[],
) => {
  // Default color for undefined ids
  if (!ids) {
    return 'transparent'
  }
  const id = Array.isArray(ids) ? ids[0] : ids
  // If we've already assigned a color to this job, return it
  if (calendarOrJobColorMap.has(id)) {
    return calendarOrJobColorMap.get(id)!
  }
  // Assign the next color in the palette
  const newColorIndex = index % palette.length
  const color = palette[newColorIndex]
  // Store the assignment for future reference
  calendarOrJobColorMap.set(id, color as string)

  return color as string
}

export const getCalendarColor = (calendarId: number) => {
  const color = selectColor(datavizPalette, calendarColorMap, calendarColorIndex, calendarId)
  // Increment the global index if a new color was assigned
  if (!calendarColorMap.has(calendarId)) {
    calendarColorIndex++
  }
  return color
}

export const getJobColor = (jobId: number) => {
  const color = selectColor(colorPalette, jobColorMap, jobColorIndex, jobId)
  // Increment the global index if a new color was assigned
  if (!jobColorMap.has(jobId)) {
    jobColorIndex++
  }
  return color
}
/**
 * Get event card colors based on interview stage, user calendar and reagrding jobs
 * @param event - event
 * @returns calendarColor - event main card background color
 * @returns interviewColor - left border color
 */
export const getEventCardColors = (event: CalendarEvent, calendar: Calendar, isVerticalResources: boolean) => {
  const regardingJobs = event.regardingJobs
  const calendarId = Number(calendar.id)

  const calendarColor = getCalendarColor(calendarId)

  let stageColor = calendarColor
  let interviewColor = calendarColor

  if (event.CandidateInterview) {
    stageColor =
      statusColorMap.get(
        getStatusColor(event.CandidateInterview?.TemplateStage?.TemplateMilestone?.name as NameEnum),
      ) || 'transparent'
  }

  if (isVerticalResources) {
    /**
     * During Schedule Interview Event:
     * calendarColor  = user's calendar color
     * interviewColor = job stage color
     */
    interviewColor = stageColor
  } else {
    /**
     * in /company/calendar page:
     * calendarColor  = user's calendar color
     * interviewColor = job listing color
     */
    if (regardingJobs) {
      interviewColor = getJobColor(regardingJobs)
    }
  }

  return { calendarColor, interviewColor }
}
